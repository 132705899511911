import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import Pernail_logo from "../../images/perennialcycle/logo-perennial.png"
import Perennialcycle from "../../images/perennialcycle/cycle.jpg"
import Perennialcycle_process from "../../images/perennialcycle/graphics.png"
import Pernail_cycle2 from "../../images/perennialcycle/cycle2.jpg"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"
import Calltoaction from "../../components/Call-to-action"

import { Link } from "gatsby";

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Case Study on Merging Blogs for SEO | An increase of +106.94% Organic sessions</title>
                    <meta name="description"
                        content="The SEO Case Study on how PerennialCycle increased YOY Organic Revenue by 155.63% with just Elemental SEO. " />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study on Merging Blogs for SEO | An increase of +106.94% Organic sessions" />
                    <meta property="og:description"
                        content="The SEO Case Study on how PerennialCycle increased YOY Organic Revenue by 155.63% with just Elemental SEO. " />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="The SEO Case Study on how PerennialCycle increased YOY Organic Revenue by 155.63% with just Elemental SEO. " />
                    <meta name="twitter:title"
                        content="Case Study on Merging Blogs for SEO | An increase of +106.94% Organic sessions" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page perennialcycle organic-perennial">
                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img src={Pernail_logo} alt="Perenial cycle logo" /></h1>
                             <p className="perennialcycle_p">How PerennialCycle increased YOY Organic Revenue by <strong>155.63%</strong> with just Elemental SEO</p>
                            
                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                      <div className="revenue-single-outer">
                                      <div className="progress blue1">
                                         <span className="progress-left">
                                            <span className="progress-bar"></span>
                                         </span>
                                         <span className="progress-right">
                                            <span className="progress-bar"></span>
                                         </span>
                                            <div className="progress-value"> 52.98%</div>
                                      </div>
                                   </div>
                                      <p>Organic sessions</p>
                                       </div>
                                    
                                       <div className="revenue-single circle3">
                                          <div className="revenue-single-outer">
                                             <div className="progress pink1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 67.25%</div>
                                             </div>
                                          </div>
                                          <p>New users</p>
                                       </div>
                                       <div className="revenue-single circle4">
                                      <div className="revenue-single-outer">
                                         <div className="progress green1">
                                            <span className="progress-left">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 155.63%</div>
                                         </div>
                                      </div> 
                                      <p>Organic revenue</p>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="cb_good_section call-action-component">
                                    <Calltoaction sid="seo-per-cycle"/>
                                 </div>

                             <div className="cycle-top"><img src={Pernail_cycle2} alt="Perenial cycle logo" /></div>
                             <p className="perennialcycle_p padding0">Perennial Cycle’s bikes, trikes & accessories are known for making people’s lives better by turning them into confident riders. Based in Uptown Minneapolis - the brand has nationwide appeal. Perennial Cycle’s team asked us to increase their online presence by improving their site’s organic visibility. This would increase the website’s footprint online and help them acquire new customers and sales.</p>
                              </div>
                           </div>

                       

                     </div>

                    <div className="steps-casestudy objectives">
                       <div className="container">
                          <div className="steps-inner">
                             <div className="step-details">
                                <div className="step-heading">
                                   <h2 className="heading_main">Objective</h2>
                                </div>
                                <ul>
                                   <li>To increase the overall organic performance of the website. </li>
                                   <li>To focus on their best-selling category (Brompton Products).</li>
                                </ul>
                             </div>

                          </div>
                       </div>
                    </div>

                    <div className="ourprocess">
                       <div className="container">

                          <div className="strategy-section">
                             <h2 className="heading_main">Our Process</h2>
                             <p>We started by performing an SEO audit to ensure that the Perennial Cycle’s website has all the elements it needs to rank. We prioritized
                                 resolving the issues that would have the biggest impact on the website’s visibility and performance.</p>
                             <p>Here are a few steps that brought success during the campaign: </p>
                          </div>
                       </div>
                    </div>

                    <div className="steps-casestudy organic-per">
                       <div className="container">
                          <div className="steps-inner">

                             <div className="step-details">
                                <div className="step-heading">
                                   <h3>Noindexing unwanted thin and duplicate pages </h3>
                                   <p>To improve the visibility of the website, we removed thin internal pages that were not serving any purpose and preventing the
                                       website from ranking. We identified thin/duplicate pages and added <strong>Noindex</strong>, <strong>follow</strong> tag on the website. </p>
                                </div>
                             </div>

                             <div className="step-details">
                                <div className="step-heading">
                                   <h3>URL Parameters </h3>
                                   <p>The URL parameter tool within the Google Search Console can be used to specify how search engines should handle parts of the website. 
                                      Faceted navigations are one of the most common ways these parameterized URLs get generated on e-commerce stores like Perennial Cycle. To control and optimize the crawling of the Perennial Cycle website, we used the URL Parameter Tool and handled the parameterized URLs that were being generated from the faceted navigation. </p>
                                </div>
                             </div>

                             <div className="step-details">
                                <div className="step-heading">
                                   <h3>Robots.txt </h3>
                                   <p>As the strategy evolved, we added a Magento specific robot.txt for the site to control the bot activity on the site. This helped in keeping a
                                       tight check on the URLs being crawled and ensured that no search engine resources were being wasted. </p>
                                </div>
                             </div>
                             {/* <div className="divier-img">
                                <img src={Perennialcycle_process} alt="perennialcycle cycle" />
                             </div> */}

                             <div className="seo-perennial-cyle-review">
                                 <div className="seo-perennial-cyle-review-heading">
                                    <span className="red-line"></span>
                                    <span className="green-line"></span>
                                 </div>
                                 <h4>“They under-promise and over-perform. They don’t glue jewels to anything, rather they speak plainly about how much time and money something will take. Then, they do it faster and better than I expect. It happens every time, and it’s unbelievable to me.”</h4>
                                 <h5>Luke Breen</h5>
                                 <h6>owner</h6>
                                 <div className="bg-img-quato">
                                 <svg width="210" height="164" viewBox="0 0 210 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.30078 163.086V150.879C25.0651 144.694 38.2487 135.01 47.8516 121.826C57.4544 108.48 62.2559 94.6452 62.2559 80.3223C62.2559 77.2298 61.7676 74.9512 60.791 73.4863C60.3027 72.5098 59.6517 72.0215 58.8379 72.0215C58.0241 72.0215 56.722 72.6725 54.9316 73.9746C49.7233 77.7181 43.2129 79.5898 35.4004 79.5898C25.9603 79.5898 17.6595 75.8464 10.498 68.3594C3.49935 60.7096 0 51.6764 0 41.2598C0 30.1921 4.06901 20.5892 12.207 12.4512C20.3451 4.15039 30.3548 0 42.2363 0C55.7454 0 67.4642 5.61523 77.3926 16.8457C87.4837 27.9134 92.5293 42.8874 92.5293 61.7676C92.5293 83.7402 85.7747 103.434 72.2656 120.85C58.7565 138.265 37.4349 152.344 8.30078 163.086ZM125.732 163.086V150.879C142.497 144.694 155.68 135.01 165.283 121.826C174.886 108.48 179.688 94.6452 179.688 80.3223C179.688 77.2298 179.199 74.9512 178.223 73.4863C177.734 72.5098 177.083 72.0215 176.27 72.0215C175.456 72.0215 174.154 72.6725 172.363 73.9746C167.155 77.7181 160.645 79.5898 152.832 79.5898C143.392 79.5898 135.091 75.8464 127.93 68.3594C120.931 60.7096 117.432 51.6764 117.432 41.2598C117.432 30.1921 121.501 20.5892 129.639 12.4512C137.777 4.15039 147.786 0 159.668 0C173.177 0 184.896 5.61523 194.824 16.8457C204.915 27.9134 209.961 42.8874 209.961 61.7676C209.961 83.7402 203.206 103.434 189.697 120.85C176.188 138.265 154.867 152.344 125.732 163.086Z" fill="#EFE2D1"/>
                                 </svg>
                                 </div>
                             </div>

                             <div className="step-details th-4">
                                <div className="step-heading">
                                   <h3>Meta Titles </h3>
                                   <p>From our audit, we also found that the Meta Titles on product and category pages had the brand name ‘Perennial Cycle’ added in the front & back for each category. We fixed these meta titles by removing the brand name ‘Perennial Cycle’ from the front as it was repetitive. 
                                      This minor correction lifted each page up the search results, producing better rankings overnight.  </p>
                                </div>
                             </div>
                             <div className="step-details th-5">
                                <div className="step-heading">
                                   <h3>Spammy Domains </h3>
                                   <p>While working on the site we discovered that there were a few Spammy Backlinks pointing to the website. To improve organic search visibility,
                                       we rectified all the spammy domains by disavowing them using the Disavow Tool in GSC (Google Search Console).</p>
                                </div>
                             </div>
                             <div className="step-details th-6">
                                <div className="step-heading">
                                   <h3>Schema Related Issues </h3>
                                   <p>Schema.org (also called Schema) is a semantic vocabulary of tags that is added to the HTML to improve the way search engines read and represent the page in SERPs (Search Engine Result Pages).
                                    <br/><br/>
                                   Due to a WordPress Plugin, there was an incorrect implementation of the Organization and WebSite Schema. We rectified this issue and switched to schma.org for structured markup implementation instead of data-vocabulary. In fact, in Jan 2019, Google announced the deprecation of data vocabulary.</p>
                                </div>
                             </div>
                             <div className="step-details th-7">
                                <div className="step-heading">
                                   <h3>Spider Traps  </h3>
                                   <p>A spider trap is a page that can lead a bot into a loop or iteration of pages. While crawling PerennialCycle, we detected more than 1 spider trap.
                                       We fixed these spider traps to improve the crawling on the website. </p>
                                </div>
                             </div>
                          </div>
                          <div className="checkout-result">
                             <h4><Link to="/case-study/google-shopping-perennial-cycle/">Check out the results of our Google Shopping work for The Perennial Cycle. </Link></h4>
                          </div>
                       </div>
                    </div>

                    <div className="cb_good_section call-action-component">
                       <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="seo-per-cycle2"/>
                        </div>
                     </div>

                     <div className="client-situation">
                        <div className="container">
                           <div className="strategy-section">
                             <h2 className="heading_main">Results</h2>
                             <p>All these steps were just the tip of the iceberg. Creating a comprehensive SEO strategy for our client was the first step to driving traffic to the website. As we keep moving down the road, we are excited to see how far the Perennial Cycle continues to grow! </p>
                             <p>CueForGood' SEO services address all the SEO needs for improving your website's visibility and organic performance. Write to us at <a href="mailto:letstalk@cueforgood.com">letstalk@cueforgood.com</a> for any SEO related services and take your business to new heights. </p>
                           </div>
                        </div>
                     </div>

                 
               

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}